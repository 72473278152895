import { template as template_d9e57bd65a3d4d1b98887374378c3113 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9e57bd65a3d4d1b98887374378c3113(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
