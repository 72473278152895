import { template as template_eb3b74954b9d4fc28a0db4a35dd9067c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_eb3b74954b9d4fc28a0db4a35dd9067c(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
