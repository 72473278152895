import { template as template_14483651ce764a8f87e8d9e8e2032d03 } from "@ember/template-compiler";
const EmptyState = template_14483651ce764a8f87e8d9e8e2032d03(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
