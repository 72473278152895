import { template as template_5c0d2e7b2069456fb096db00c99050ec } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5c0d2e7b2069456fb096db00c99050ec(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
