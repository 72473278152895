import { template as template_12318ba5b6f845caab59d9bf1a5f166e } from "@ember/template-compiler";
const FKText = template_12318ba5b6f845caab59d9bf1a5f166e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
